<template>
    <div>
        <div class="right-menu shipping-right">

            <subHeader pageName="BLOG CATEGORIES"/>

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <moduleSearch :moduleName="'blog_category'" :disable="tourIsActive" ref="module_search"/>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="blogCategoriesTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == blogCategoriesList.length"
                                                        :disabled="tourIsActive || this.previewForm.id != ''"
                                                    >
                                                    <span class="checkmark"></span>
                                                </label>
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Image">Image</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Name">Name</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Type">Type</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Parent Category">Parent Category</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Active/Disable">Active/Disable</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Live Preview">Live Preview</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="blogCategoriesLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div> 
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="50"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="100"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="100"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="100"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>

                                    <tr v-if="blogCategoriesList && blogCategoriesList.length == 0">
                                        <td colspan="7" style="text-align:center"><p class="mb-0">No Blog Categories Available</p></td>
                                    </tr>
                                    <tr v-for="(blogCategory, index) in blogCategoriesList" :key="index">

                                        <!-- checkbox & image block starts here -->
                                        <td @click="previewBlogCategory(blogCategory,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <label 
                                                    class="ctmTableCheckContainer" 
                                                    data-open="false" 
                                                    @click.prevent=" blogCategory.id != -1 ? checkedItemHandle(blogCategory.id) : '' "
                                                >
                                                    <input 
                                                        type="checkbox" 
                                                        v-model="checkedItems" 
                                                        :value="blogCategory.id" 
                                                        :disabled="tourIsActive || blogCategory.id == -1" 
                                                        data-open="false"
                                                    >
                                                    <span class="checkmark" data-open="false"></span>
                                                </label>
                                                <!-- for saved category -->
                                                <div class="ctmDataTableImgBox" data-open="true" v-if="blogCategory.id != -1">
                                                    <img 
                                                        :src="blogCategory.image ? blogCategory.image_src : '/images/no_image.png'" 
                                                        alt=""
                                                        v-if="blogCategory.id == -1"
                                                        data-open="true"
                                                        @error="handleImageError"
                                                    >
                                                    <img
                                                        :src="generateUrl(blogCategory) ? generateUrl(blogCategory) : '/images/no_image.png'"
                                                        alt=""
                                                        data-open="true"
                                                        @error="handleImageError"
                                                        v-else
                                                    >
                                                </div>
                                                <!-- for unsaved category -->
                                                <div class="ctmDataTableImgBox" data-open="true" v-else>
                                                    <img 
                                                        :src="blogCategory.addImage ? blogCategory.addImage_src : '/images/no_image.png'" 
                                                        alt=""
                                                        v-if="blogCategory.id == -1"
                                                        data-open="true"
                                                        @error="handleImageError"
                                                    >
                                                    <img
                                                        :src="generateUrl(blogCategory) ? generateUrl(blogCategory) : '/images/no_image.png'"
                                                        alt=""
                                                        data-open="true"
                                                        @error="handleImageError"
                                                        v-else
                                                    >
                                                </div>
                                            </div>
                                        </td>
                                        <!-- checkbox & image block starts here -->

                                        <!-- blog category name block starts here -->
                                        <td class="mainTableTitle" @click="previewBlogCategory(blogCategory,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="blog-category-name-field-3">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a blog category name"
                                                    v-model="blogCategory.name" 
                                                    @focus="blogCategorySelection(blogCategory)" 
                                                    @blur="handleOTGUpdate(blogCategory,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- blog category name block ends here -->

                                        <!-- blog category type block starts here -->
                                        <td @click.prevent="previewBlogCategory(blogCategory,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <!-- <p data-open="true">{{ blogCategory.parent_id == null ? 'Main' : 'Sub' }}</p> -->
                                                <p data-open="true">{{ blogCategory.id != previewForm.id ? blogCategory.parent_id == null ? 'Main' : 'Sub' : selectedCategoryType == 'main' ? 'Main' : 'Sub' }}</p>

                                            </div>
                                        </td>
                                        <!-- blog category type block ends here -->

                                        <!-- parent category block starts here -->
                                        <td style="position: relative;" @click.prevent="previewBlogCategory(blogCategory,index,'solid',$event)">
                                            <div class="selectCategoryBoxTable" data-open="true">
                                                <p data-open="true">
                                                    {{
                                                        blogCategory.id == previewForm.id ? blogCategory.parent_id ? 
                                                        blogCategory.parent_id.name.length > 30 ? blogCategory.parent_id.name.substr(0, 30) + "..." : 
                                                        blogCategory.parent_id.name : '--' : blogCategory.parents ? blogCategory.parents.length == 0 ? '--' :
                                                        blogCategory.parents[0].name.length > 30 ? blogCategory.parents[0].name.substr(0, 30) + "..." : 
                                                        blogCategory.parents[0].name : '--'
                                                    }}
                                                    <!-- {{ blogCategory.parents ? blogCategory.parents.length == 0 ? '--' : 
                                                        blogCategory.parents[0].name.length > 30 ? blogCategory.parents[0].name.substr(0, 30) + "..." : 
                                                        blogCategory.parents[0].name : '--'
                                                    }} -->
                                                </p>
                                            </div>
                                        </td>
                                        <!-- parent category block ends here -->

                                        <!-- active/disabled block starts here-->
                                        <td @click="previewBlogCategory(blogCategory,index,'input',$event)">
                                            <div class="actionSwitch" data-open="true">
                                                <label class="switch" data-open="false" v-if="blogCategory.id != -1">
                                                    <input
                                                        type="checkbox"
                                                        :id="`customSwitch-p-${blogCategory.id}`"
                                                        :value="blogCategory.isActive"
                                                        v-model="blogCategory.isActive"
                                                        :disabled="tourIsActive"
                                                        data-open="false"
                                                    >
                                                    <span class="slider" data-open="false" @click.stop="updateBlogCategoryStatusHandle(blogCategory,index)"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- active/disabled block ends here-->

                                        <!-- view block starts here -->
                                        <td @click.prevent="previewBlogCategory(blogCategory,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" data-open="true">
                                                <button 
                                                    class="previewFunction" 
                                                    @click.prevent="livePreview(blogCategory)" 
                                                    :disabled="tourIsActive && blogCategory.id == -1" 
                                                    data-open="false"
                                                >
                                                    <i class="fa fa-external-link-square" aria-hidden="true" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- view block ends here -->

                                        <!-- action block starts here -->
                                        <td @click.prevent="previewBlogCategory(blogCategory,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(blogCategory.id) && deleteLoader" data-open="false">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button 
                                                    class="delFunction" 
                                                    @click.prevent="deleteBlogCategoryHandle(blogCategory,index)" 
                                                    :disabled="tourIsActive" 
                                                    data-open="false"
                                                >
                                                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->

                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div> 
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="50"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="100"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="50"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="100"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                    <!-- load more block ends here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- add/update blog category form starts here -->
                        <div class="productSlideBox" ref="viewInputForm" :class="previewForm.id ? 'activeRow': ''" >
                            <div class="tableActionBtns" id="blog-category-details-preview">
                                <div class="productTableSideBar">
                                    <div class="row">
                                        <div class="col-md-8" id="blog-category-name-field-2">
                                            <div class="viewOrderTitle">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a blog category name" 
                                                    v-model="previewForm.name" 
                                                    @input="slugGeneration('name')"
                                                    :disabled="tourIsActive"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="orderViewNav">
                                                <ul>
                                                    <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                        <li class="sm-extand-hide">
                                                            <button @click.prevent="expand" :disabled="tourIsActive" id="expand-form">
                                                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                        <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                        <path d="m16.5.522-6 5.907"/>
                                                                        <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                        <path d="m16.5 16.429-6-5.907"/>
                                                                        <path d="m.5 5.5v-5h5.5"/>
                                                                        <path d="m6.5 6.429-6-5.907"/>
                                                                        <path d="m6 16.516-5.5.007v-5.023"/>
                                                                        <path d="m6.5 10.5-6 6"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                    <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                        <li>
                                                            <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                    <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                        <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                        <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                            <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sectionTabsLink">
                                    <ul onclick="window.myFunction(event)">
                                        <li v-if="!tourIsActive">
                                            <a class="active" id="category-detail-navbtn" href="#blogCategoryDetails">Blog Category Info</a>
                                        </li>
                                        <li v-if="!tourIsActive">
                                            <a href="#seo" id="category-seo-navbtn">SEO Essentials</a>
                                        </li>
                                    </ul>
                                    <div class="updateBtnBox" v-if="blogCategoryIsSaving">
                                        <loaderBtn/>
                                    </div>
                                    <div class="updateBtnBox" id="save-blog-category-btn" v-else>
                                        <button 
                                            form="blog-category-information" 
                                            type="submit"
                                            :disabled="tourIsActive"
                                        >
                                            {{ previewForm.id == -1 ? 'Save' : 'Update' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ValidationObserver ref="blogCategoryFormObserver">
                                <div class="productTableInfoBlock" id="blogCategoryDetails">
                                    <div class="productTableInfoWhiteBox">
                                        <form id="blog-category-information" @submit.prevent="validate()">
                                            <div class="tableFromFieldBox">
                                                <label for="blog-category-name-field-1">Name<span>*</span></label>
                                                <ValidationProvider name="name" rules="required|max:250" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="blog-category-name-field-1" 
                                                            id="blog-category-name-field-1" 
                                                            placeholder="Name" 
                                                            v-model="previewForm.name" 
                                                            @input="slugGeneration('name')" 
                                                            :disabled="!previewForm.created_by_user || tourIsActive"
                                                        >
                                                        <p class="fieldSeoMessage" v-if="previewForm.name && previewForm.name.length <= 50">
                                                            <span style="display: inline-flex;margin-right: 5px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                                </svg> 
                                                            </span>
                                                            Do not exceed 50 characters when entering the category name for SEO
                                                        </p>
                                                        <p class="fieldSeoMessage" v-else-if="previewForm.name && previewForm.name.length > 50"> 
                                                            <span style="display: inline-flex;margin-right: 5px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                                </svg> 
                                                            </span>
                                                            Not SEO friendly
                                                        </p>
                                                        <p class="remainingTxt" v-if="previewForm.name">
                                                            <span>
                                                                {{previewForm.name ? previewForm.name.length <= 50 ? 50 - previewForm.name.length : 0 : 50 }} 
                                                            </span>
                                                            Remaining
                                                        </p>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="blog-category-slug-field">Slug<span>*</span></label>
                                                <ValidationProvider name="slug" rules="required|max:250" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="blog-category-slug-field" 
                                                            id="blog-category-slug-field" 
                                                            placeholder="Slug" 
                                                            v-model="previewForm.slug" 
                                                            @blur="slugGeneration('slug')" 
                                                            :disabled="!previewForm.created_by_user || tourIsActive"
                                                        >
                                                        <p class="fieldSeoMessage" v-if="previewForm.slug && previewForm.slug.length <= 60">
                                                            <span style="display: inline-flex;margin-right: 5px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z" />
                                                                </svg> 
                                                            </span>
                                                            Do not exceed 60 characters when entering the category slug for SEO
                                                        </p>
                                                        <p class="fieldSeoMessage" v-else-if="previewForm.slug && previewForm.slug.length > 60"> 
                                                            <span style="display: inline-flex;margin-right: 5px;">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                    <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z" />
                                                                </svg> 
                                                            </span>
                                                            Not SEO friendly
                                                        </p>
                                                        <p class="remainingTxt" v-if="previewForm.slug">
                                                            <span>
                                                                {{previewForm.slug ? previewForm.slug.length <= 60 ? 60 - previewForm.slug.length : 0 : 60 }} 
                                                            </span>
                                                            Remaining
                                                        </p>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="blog-category-excerpt-field">Excerpt<span>*</span></label>
                                                <ValidationProvider name="excerpt" rules="required|max:250" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="blog-category-excerpt-field" 
                                                            id="blog-category-excerpt-field"
                                                            placeholder="Excerpt" 
                                                            v-model="previewForm.excerpt"
                                                            :disabled="tourIsActive"
                                                        >
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="blog-category-type-field">Category Type<span>*</span></label>
                                                <ValidationProvider name="category type" rules="required" v-slot="{ errors }">
                                                    <div class="vueCtmSelectBlock">
                                                        <select
                                                            name="blog-category-type-field" 
                                                            id="blog-category-type-field" 
                                                            v-model="selectedCategoryType" 
                                                            :disabled="!previewForm.created_by_user || tourIsActive"
                                                        >
                                                            <option value="main">Main</option>
                                                            <option value="sub">Sub</option>
                                                        </select>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox" v-if="selectedCategoryType == 'sub'" id="parent-category-section">
                                                <label for="parent-category-field">Parent Category<span>*</span></label>
                                                <div class="vueSelectBlock">
                                                <ValidationProvider 
                                                    name="parent category" 
                                                    :rules="selectedCategoryType == 'sub' ? 'required' : ''" 
                                                    v-slot="{ errors }"
                                                >
                                                    <Multiselect
                                                        ref="parentSelection"
                                                        id="parent-category-field"
                                                        v-model="previewForm.parent_id"
                                                        :options="parentCategoriesList"
                                                        placeholder="Search and select a parent category"
                                                        :custom-label="customProductCategoryName"
                                                        label="name"
                                                        track-by="id"
                                                        :allow-empty="false"
                                                        deselect-label="Can't remove the selected item"
                                                        :searchable="true"
                                                        @search-change="loadBlogCategories"
                                                        :loading="parentCategoriesLoading"
                                                        :disabled="tourIsActive"
                                                    >
                                                        <span slot="noResult">
                                                            No category found matching the searched keyword.
                                                        </span>
                                                    </Multiselect>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                                </div>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <ValidationProvider name="description" rules="required|max:3000" v-slot="{ errors }">
                                                    <label for="blog-category-description-field">Description<span>*</span></label>
                                                    <div class="tableFromFieldItem">
                                                        <textarea 
                                                            name="blog-category-description-field" 
                                                            id="blog-category-description-field" 
                                                            placeholder="Description" 
                                                            v-model="previewForm.description"
                                                            :disabled="tourIsActive"
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox" v-if="previewForm.id == -1">
                                                <label for="sDiscription">Featured Image<span>*</span></label>
                                                <div class="imgUploaderTxt">
                                                    <input 
                                                        type="file" 
                                                        ref="addPreviewFileInput" 
                                                        @change.prevent="changeImage" 
                                                        id="feature_img" 
                                                        accept="image/png,image/jpg,image/jpeg,image/webp" 
                                                        hidden 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <label for="feature_img">
                                                    <div class="category-tab-img" id="category-featured-image">
                                                        <img 
                                                            :src="previewForm.addImage ? 
                                                            previewForm.addImage_src ? 
                                                            previewForm.addImage_src : base_image_url + setting.store_id + '/blog_category/' + previewForm.addImage : '/images/no_image.png' "
                                                            alt=""
                                                            @error="handleImageError"
                                                        />
                                                    </div>
                                                    </label>
                                                </div>
                                                <span class="text-danger" v-if="imageValidations.imgIsRequired">Image field is required</span>
                                            </div>
                                            <div class="tableFromFieldBox" id="category-featured-image-update" v-else>
                                                <label for="sDiscription">Featured Image<span>*</span></label>
                                                <div class="imgUploaderTxt">
                                                <input 
                                                    type="file" 
                                                    ref="previewFileInput" 
                                                    @change.prevent="changeImagePreviewForm" 
                                                    id="feature_img1" 
                                                    accept="image/png,image/jpg,image/jpeg,image/webp" 
                                                    hidden 
                                                    :disabled="tourIsActive"
                                                >
                                                <label for="feature_img1">
                                                    <div class="category-tab-img">
                                                        <img 
                                                            :src="previewForm.image ? 
                                                            previewForm.image_src ? 
                                                            previewForm.image_src : 
                                                            base_image_url + settings.store_id + '/blog_category/' + previewForm.image : '/images/no_image.png' " 
                                                            alt=""
                                                            @error="handleImageError"
                                                        />
                                                    </div>
                                                </label>
                                                </div>
                                                <span class="text-danger" v-if="imageValidations.imgIsRequired">Image field is required</span>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="productTableInfoWhiteBox" id="seo">
                                        <div class="productTableInfoWhiteBoxTitle">
                                            <h5>SEO</h5>
                                        </div>
                                        <div class="tableFromFieldBox">
                                            <ValidationProvider name="meta title" rules="max:250" v-slot="{ errors }">
                                                <label for="blog-category-meta-title-field">Meta Title</label>
                                                <div class="tableFromFieldItem">
                                                    <input
                                                        type="text" 
                                                        name="blog-category-meta-title-field" 
                                                        id="blog-category-meta-title-field" 
                                                        form="blog-category-information"
                                                        placeholder="Meta title"
                                                        v-model="previewForm.meta_title"
                                                        :disabled="tourIsActive"
                                                    >
                                                    <p class="fieldSeoMessage" v-if="previewForm.meta_title && previewForm.meta_title.length <= 60">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                        </svg> 
                                                        Do not exceed 60 characters when entering the category meta title for SEO
                                                    </p>
                                                    <p class="fieldSeoMessage" v-else-if="previewForm.meta_title && previewForm.meta_title.length > 60">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                        </svg> 
                                                        Not SEO friendly
                                                    </p>
                                                    <p class="remainingTxt" v-if="previewForm.meta_title">
                                                        <span>{{previewForm.meta_title ? previewForm.meta_title.length <= 60 ? 60 - previewForm.meta_title.length : 0 : 60 }}</span> Remaining
                                                    </p>
                                                </div>
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                        <div class="tableFromFieldBox">
                                            <ValidationProvider name="meta description" rules="max:500" v-slot="{ errors }">
                                                <label for="blog-category-meta-description-field">Meta Description</label>
                                                <div class="tableFromFieldItem">
                                                    <textarea
                                                        name="blog-category-meta-description-field" 
                                                        id="blog-category-meta-description-field" 
                                                        form="blog-category-information"
                                                        placeholder="Meta description" 
                                                        v-model="previewForm.meta_description"
                                                        :disabled="tourIsActive"
                                                    >
                                                    </textarea>
                                                    <p class="fieldSeoMessage" v-if="previewForm.meta_description && previewForm.meta_description.length <= 300">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                        </svg> 
                                                        Do not exceed 300 characters when entering the category meta desscription for SEO
                                                    </p>
                                                    <p class="fieldSeoMessage" v-else-if="previewForm.meta_description && previewForm.meta_description.length > 300">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                        </svg> 
                                                        Not SEO friendly
                                                    </p>
                                                    <p class="remainingTxt" v-if="previewForm.meta_description">
                                                        <span>{{previewForm.meta_description ? previewForm.meta_description.length <= 300 ? 300 - previewForm.meta_description.length : 0 : 300 }}</span> Remaining
                                                    </p>
                                                </div>
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                        <div class="ctmFlexRow">
                                            <div class="statusColumnBox">
                                                <div class="tableFromFieldBox" id="blog-category-active-switch">
                                                    <label>Status</label>
                                                    <div class="actionSwitch">
                                                        <label class="switch">
                                                            <input 
                                                                type="checkbox"
                                                                :value="previewForm.isActive"
                                                                v-model="previewForm.isActive"
                                                                :disabled="tourIsActive"
                                                            >
                                                            <span class="slider"></span>
                                                            <p class="ctmSwitchLabel">{{previewForm.isActive ? 'Active' : 'inActive'}}</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="statusColumnBox">
                                                <div class="tableFromFieldBox" id="blog-category-indexed-switch">
                                                    <label>Indexed</label>
                                                    <div class="actionSwitch">
                                                        <label class="switch">
                                                            <input
                                                                type="checkbox"
                                                                :value="previewForm.isIndexed"
                                                                v-model="previewForm.isIndexed"
                                                                :disabled="tourIsActive"
                                                            >
                                                            <span class="slider"></span>
                                                            <p class="ctmSwitchLabel">{{previewForm.isIndexed ? 'Yes' : 'No'}}</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <!-- add/update blog category form ends here -->

                    </div>
                    <!-- data table ends here -->

                    <!-- bottom action bar starts here -->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != blogCategoriesList.length">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-4" @click.prevent="blogCategoriesBulkActionHandle('active')">
                                    <p>ACTIVE</p>
                                </a>
                                <a href="" class="model-footer-1 mr-4" @click.prevent="blogCategoriesBulkActionHandle('disable')">
                                    <p>DISABLE</p>
                                </a>
                                <a href="" class="model-footer-1 ml-4" @click.prevent="blogCategoriesBulkActionHandle('delete')">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    <p>DELETE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- bottom action bar ends here -->

                </div>
            </div>

        </div>

        <!--vue tour-->
        <v-tour name="blogCategoriesTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import {MessageBox, Message, Loading} from 'element-ui'
import _ from 'lodash';
import subHeader from "@/components/subHeader.vue";
import { mapGetters } from 'vuex';
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";
import moduleStats from "@/components/moduleStats.vue";
import moduleSearch from "@/components/ModuleSearch/index.vue";
import Multiselect from "vue-multiselect";

export default {
    name: 'blogCategory',
    metaInfo() {
        return {
            title: 'Content Management | Blog Category | YeetCommerce',
        };
    },
    data() {
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,

            //blog categories start
            blogCategoriesList: [],
            parentCategoriesList: [],
            parentCategoriesLoading: false,
            parentCategoriesTimer: undefined,
            blogCategoriesLoading: false,
            blogCategorySaved: false,
            previewForm:{
                id: '',
                name: '',
                slug: '',
                excerpt: '',
                description: '',
                meta_title: '',
                meta_description: '',
                parent_id: null,
                parents: [],
                addImage: null,
                addImage_src: '',
                image: null,
                image_src: '',
                isActive: true,
                isIndexed: true,
                isTouched: false,
                created_by_user: 'admin',
            },
            defaultPreviewForm:{
                id: '',
                name: '',
                slug: '',
                excerpt: '',
                description: '',
                meta_title: '',
                meta_description: '',
                parent_id: null,
                parents: [],
                addImage: null,
                addImage_src: '',
                image: null,
                image_src: '',
                isActive: true,
                isIndexed: true,
                isTouched: false,
                created_by_user: 'admin',
            },
            tempBlogCategory:{
                id: '',
                name: '',
                slug: '',
                excerpt: '',
                description: '',
                meta_title: '',
                meta_description: '',
                parent_id: null,
                parents: [],
                addImage: null,
                addImage_src: '',
                image: null,
                image_src: '',
                isActive: true,
                isIndexed: true,
                isTouched: false,
                created_by_user: 'admin',
            },
            tempPreviewCategory:{
                id: '',
                name: '',
                slug: '',
                excerpt: '',
                description: '',
                meta_title: '',
                meta_description: '',
                parent_id: null,
                parents: [],
                addImage: null,
                addImage_src: '',
                image: null,
                image_src: '',
                isActive: true,
                isIndexed: true,
                isTouched: false,
                created_by_user: 'admin',
            },
            blogCategoryIsSaving: false,
            selectedCategoryType: '',
            selectedParent: {},
            //blog categories end

            //image validations start
            imageValidations:{
                imgIsRequired: false,
                imgInvalidFormat: false,
                isNotImg: false,
            },
            //image validations end

            //checked items start
            checkedItems: [],
            deletingItems: [],
            deleteLoader: false,
            isMasterSearched: false,
            //checked items end

            //tour start
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //search bar step
                {
                    target: '#module-search',
                    content: `<strong>Search Blog Categories</strong><br>It enables you to search specific blog categories.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                //blog categories steps
                {
                    target: '#preview-record',
                    content: `<strong>Blog Categories Table</strong><br>You can preview and edit the added blog categories by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'top'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add a <strong>Blog Category</strong><br>By clicking this button, you can add a new blog category and open a form to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-category-details-preview',
                    content: `<strong>Blog Category Details Form</strong><br>This form can be used to add details about the blog category and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-category-name-field-1',
                    content: `<strong>Blog Category Name Field</strong><br>You can add the name of blog category using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-category-slug-field',
                    content: `<strong>Blog Category Slug Field</strong><br>You can add the slug of blog category using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-category-excerpt-field',
                    content: `<strong>Blog Category Excerpt Field</strong><br>You can add the excerpt of blog category using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-category-type-field',
                    content: `<strong>Category Type Selection</strong><br>You can select the type of category using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#parent-category-section',
                    content: `<strong>Parent Category Selection</strong><br>If you are adding a sub category, parent category selection is a must.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-category-description-field',
                    content: `<strong>Blog Category Description</strong><br>Blog category description can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#category-featured-image',
                    content: `<strong>Blog Category Featured Image</strong><br>It enables you to add featured image for the blog category`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-category-meta-title-field',
                    content: `<strong>Blog Category Meta Title</strong><br>Meta title for the blog category can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-category-meta-description-field',
                    content: `<strong>Blog Category Meta Description</strong><br>Meta description for the blog category can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#blog-category-active-switch',
                    content: `<strong>Blog Category Status</strong><br>Blog category status can be changed between active and hide using this switch.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'right'
                    }
                },
                {
                    target: '#blog-category-indexed-switch',
                    content: `<strong>Blog Category Indexed Status</strong><br>Blog category index status can be changed between yes and no using this switch.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#blog-category-name-field-3',
                    content: `<strong>Blog Category Name Field</strong><br>You can also add the name of blog category using this field too.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'right'
                    }
                },
                {
                    target: '#blog-category-name-field-2',
                    content: `<strong>Blog Category Name Field</strong><br>You can also add the name of blog category using this field too.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'right'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#save-blog-category-btn',
                    content: `<strong>Save Blog Category Details</strong><br>Added blog category details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
            ],
            tourIsActive: false,
            //tour end
        }
    },
    components:{
        subHeader,
        tutorialsAction,
        moduleStats,
        loaderBtn,
        Multiselect,
        moduleSearch,
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
            storeBlogCategories:'blogCategories_module/blogCategories',
            blogCategoriesCurrentPage: 'blogCategories_module/currentPage',
            blogCategoriesLastPage: 'blogCategories_module/lastPage',
            blogCategorySearchKeyword:'blogCategories_module/keyword',
            isPending:'blogCategories_module/isPending',
        }),
    },
    watch:{
        'storeBlogCategories':{
            handler:function(value){

                this.blogCategoriesLoading = false;

                if(this.previewForm.id == -1){
        
                    this.blogCategoriesList = _.cloneDeep(value);
                    
                    this.blogCategoriesList.unshift(this.previewForm);

                }else if(this.tempPreviewCategory.id != ''){

                    this.blogCategoriesList = _.cloneDeep(value);

                    this.blogCategoriesList[this.previewForm.index] = this.previewForm;

                }else{

                    this.blogCategoriesList = _.cloneDeep(value);

                }

                if(this.isMasterSearched){

                    this.previewSearchedBlogCategory(this.blogCategoriesList[0],0);

                    this.isMasterSearched = false;

                }


            },deep:true
        },
        'previewForm':{
            handler:function(value){

                if((value.id == -1) && (value.name || value.slug || value.excerpt || value.description 
                || value.image || value.addImage || value.meta_title || value.meta_description )){

                    value.isTouched = true;

                }else{

                    value.isTouched = false;

                }

            },deep:true
        },
    },
    methods:{
        //infinite scroll start
        handleScrollEvent(event){

            let element = this.$refs.blogCategoriesTable;

            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.blogCategoriesCurrentPage != this.blogCategoriesLastPage){

                    this.$store.dispatch('blogCategories_module/fetchStoreBlogCategoriesRequest',{size: 15, page: this.blogCategoriesCurrentPage + 1, loadData: 'more'});

                }

            }

        },
        //infinite scroll end

        //fields handling start
        slugGeneration(field){

            let slug = ''

            if(field == 'name'){

                slug = this.previewForm.name.toLowerCase().trim();
                slug = slug.replace(/[^a-zA-Z0-9 ]/g, '');
                slug = slug.replace(/\s/g, '-');
                slug = slug.replace(/(\W)\W*/g, '$1');
                slug = slug.replace(/-$/, ""); 

            }else if(field == 'slug'){

                slug = this.previewForm.slug.toLowerCase().trim();
                slug = slug.replace(/[^a-zA-Z0-9- ]/g, '');
                slug = slug.replace(/\s/g, '-');
                slug = slug.replace(/(\W)\W*/g, '$1');

            }

            this.previewForm.slug = slug;

        },
        //fields handling end

        //OTG update start
        blogCategorySelection(listedBlogCategory){

            this.tempBlogCategory = _.cloneDeep(listedBlogCategory);

        },
        async handleOTGUpdate(listedBlogCategory,index){

            if(this.previewForm.id == ''){

                if(listedBlogCategory.id != -1){

                    if(this.tempBlogCategory.name != listedBlogCategory.name){

                        if(!listedBlogCategory.created_by_user){

                            if(this.tempBlogCategory.name != listedBlogCategory.name){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Changes Discarded',
                                    message: "Default blog category cannot be modified.",
                                });

                                this.blogCategoriesList[index].name = this.tempBlogCategory.name;

                            }

                            return

                        }

                        if(listedBlogCategory.name.length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The name field is required.",
                            })

                            this.blogCategoriesList[index].name = this.tempBlogCategory.name;

                            return

                        }

                        if(listedBlogCategory.name.length > 250){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The name field may not be greater than 250 characters.",
                            });

                            this.blogCategoriesList[index].name = this.tempBlogCategory.name;

                            return

                        }

                        MessageBox.confirm(
                            'You have unsaved changes, save and proceed?',
                            'Confirm',
                            {
                                distinguishCancelAndClose: true,
                                confirmButtonText: 'Save',
                                cancelButtonText: 'Discard Changes',
                            }
                        ).then(async () => {

                            let formData = new FormData();

                            formData.append('field','name');
                            formData.append('blog_category_id',listedBlogCategory.id);

                            formData.append('name',listedBlogCategory.name);

                            let loader = Loading.service({
                                text: "The blog category is being updated. Please wait!",
                                fullscreen: true,
                            });

                            try{
                                let res = await this.$axios.post('/blog-category/selective/update', formData);
                                if(res.data.status_code == '10432'){
                                    
                                    this.$notify({
                                        type: 'success',
                                        title: 'Success',
                                        message: res.data.message,
                                    });

                                    this.$store.commit('blogCategories_module/update_blogCategories_list',res.data.category);

                                    this.$store.commit('gallery_module/CLEAR_MODULE');

                                    this.$store.commit('blogs_module/CLEAR_MODULE');

                                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                                }
                            }catch(error){

                                this.blogCategoriesList[index].name = this.tempBlogCategory.name;
                                this.blogCategoriesList[index].slug = this.tempBlogCategory.slug;

                                this.tempBlogCategory = [];

                                if(error.response){

                                    if(error.response.data.error.id){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.id[0],
                                        });

                                    }else if(error.response.data.error.name){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.name[0],
                                        });

                                    }else if(error.response.data.error.slug){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.slug[0],
                                        });

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.message,
                                        });

                                    }

                                }else{

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.message,
                                    });

                                }

                            }finally{

                                loader.close();

                            }

                        }).catch((error) => {

                            this.blogCategoriesList[index].name = this.tempBlogCategory.name;
                            this.blogCategoriesList[index].slug = this.tempBlogCategory.slug;

                            this.$notify({
                                type: "info",
                                title: "Changes Discarded",
                                message: "Changes discarded successfully.",
                            });

                            this.tempBlogCategory = [];

                        });

                    }

                }

            }else{

                if(listedBlogCategory.id != -1){
                    
                    if(listedBlogCategory.id != this.previewForm.id){

                        if(this.tempBlogCategory.name != listedBlogCategory.name){

                            if(!listedBlogCategory.created_by_user){

                                if(this.tempBlogCategory.name != listedBlogCategory.name){

                                    this.$notify({
                                        type: 'warning',
                                        title: 'Changes Discarded',
                                        message: "Default blog category cannot be modified.",
                                    });

                                    this.blogCategoriesList[index].name = this.tempBlogCategory.name;

                                }

                                return

                            }

                            if(listedBlogCategory.name.length == 0){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The name field is required.",
                                })

                                this.blogCategoriesList[index].name = this.tempBlogCategory.name;

                                return

                            }

                            if(listedBlogCategory.name.length > 250){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The name field may not be greater than 250 characters.",
                                });

                                this.blogCategoriesList[index].name = this.tempBlogCategory.name;

                                return

                            }

                            MessageBox.confirm(
                                'You have unsaved changes, save and proceed?',
                                'Confirm',
                                {
                                    distinguishCancelAndClose: true,
                                    confirmButtonText: 'Save',
                                    cancelButtonText: 'Discard Changes',
                                }
                            ).then(async () => {

                                let formData = new FormData();

                                formData.append('field','name');
                                formData.append('blog_category_id',listedBlogCategory.id);

                                formData.append('name',listedBlogCategory.name);

                                let loader = Loading.service({
                                    text: "The blog category is being updated. Please wait!",
                                    fullscreen: true,
                                });

                                try{
                                    let res = await this.$axios.post('/blog-category/selective/update', formData);
                                    if(res.data.status_code == '10432'){
                                        
                                        this.$notify({
                                            type: 'success',
                                            title: 'Success',
                                            message: res.data.message,
                                        });

                                        this.$store.commit('blogCategories_module/update_blogCategories_list',res.data.category);

                                        this.$store.commit('gallery_module/CLEAR_MODULE');

                                        this.$store.commit('blogs_module/CLEAR_MODULE');

                                        this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                                    }
                                }catch(error){

                                    this.blogCategoriesList[index].name = this.tempBlogCategory.name;
                                    this.blogCategoriesList[index].slug = this.tempBlogCategory.slug;

                                    this.tempBlogCategory = [];

                                    if(error.response){

                                        if(error.response.data.error.id){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error.id[0],
                                            });

                                        }else if(error.response.data.error.name){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error.name[0],
                                            });

                                        }else if(error.response.data.error.slug){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error.slug[0],
                                            });

                                        }else{

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.message,
                                            });

                                        }

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.message,
                                        });

                                    }

                                }finally{

                                    loader.close();

                                }

                            }).catch(() => {

                                this.blogCategoriesList[index].name = this.tempBlogCategory.name;
                                this.blogCategoriesList[index].slug = this.tempBlogCategory.slug;

                                this.$notify({
                                    type: "info",
                                    title: "Changes Discarded",
                                    message: "Changes discarded successfully.",
                                });

                                this.tempBlogCategory = [];

                            });

                        }

                    }

                }

            }

        },
        //OTG update end

        //preview form open/close & reset start
        previewBlogCategory(listedBlogCategory,i,field,e){

            if(field == 'input'){

                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){

                    if(listedBlogCategory.id != -1){
                        
                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    type: "warning",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                }
                            ).then(()=>{

                                let index = this.blogCategoriesList.findIndex((category) => category.id == -1);
                                
                                if(index != -1){
                    
                                    this.blogCategoriesList.splice(index, 1);
                    
                                }

                                if(!this.blogCategorySaved){

                                    let tempIndex = this.blogCategoriesList.findIndex(x=>x.id == this.tempPreviewCategory.id);

                                    if(tempIndex != -1){

                                        this.blogCategoriesList[tempIndex] = this.tempPreviewCategory;

                                    }

                                }

                                this.resetImageValidations();

                                let blogCategory = listedBlogCategory;

                                this.previewForm = blogCategory;
                                this.previewForm.parent_id = blogCategory.parents.length != 0 ? blogCategory.parents[0] : null;
                                this.previewForm.index = i;
                                this.previewForm.image_src = null;
                                this.previewForm.image = blogCategory.image;

                                this.checkedItems = [];

                                if(blogCategory.parent_id == null){

                                    this.selectedCategoryType = 'main';

                                }else{

                                    this.selectedCategoryType = 'sub';

                                    this.selectedParent = blogCategory.parents[0];

                                    let categoryIndex = this.parentCategoriesList.findIndex((listedCategory) => listedCategory.id == blogCategory.parents[0].id)

                                    if(categoryIndex == -1){

                                        this.loadBlogCategories(null);

                                    }

                                }
                                
                                // this.parentCategoriesList = this.blogCategoriesList.filter((x)=>{

                                //     if(x.id != blogCategory.id && x.created_by_user){

                                //         return x;

                                //     }

                                // });

                                this.$refs.blogCategoryFormObserver.reset();

                                this.tempPreviewCategory = _.cloneDeep(blogCategory);

                            }).catch(() => {})

                        }else{

                            let index = this.blogCategoriesList.findIndex((category) => category.id == -1);
                                
                            if(index != -1){
                
                                this.blogCategoriesList.splice(index, 1);
                
                            }
                
                            if(!this.blogCategorySaved && (this.tempPreviewCategory.id != '' && this.tempPreviewCategory.id != listedBlogCategory.id)){
                                
                                let tempIndex = this.blogCategoriesList.findIndex(x=>x.id == this.tempPreviewCategory.id);
                
                                if(tempIndex != -1){

                                    this.blogCategoriesList[tempIndex] = this.tempPreviewCategory;

                                }
                
                            }

                            if(this.tempPreviewCategory.id != '' && this.tempPreviewCategory.id == listedBlogCategory.id){

                                let tempIndex = this.blogCategoriesList.findIndex(x=>x.id == this.tempPreviewCategory.id);

                                this.blogCategoriesList[tempIndex] = _.cloneDeep(this.storeBlogCategories[tempIndex]);

                                this.$refs.previewFileInput.value = ''

                                this.resetImageValidations();

                                let blogCategory = _.cloneDeep(this.blogCategoriesList[tempIndex]);

                                this.previewForm = this.blogCategoriesList[tempIndex];
                                this.previewForm.parent_id = blogCategory.parents.length != 0 ? blogCategory.parents[0] : null;
                                this.previewForm.index = i;
                                this.previewForm.image_src = null;
                                this.previewForm.image = blogCategory.image;

                                this.checkedItems = [];

                                if(blogCategory.parent_id == null){

                                    this.selectedCategoryType = 'main';

                                }else{

                                    this.selectedCategoryType = 'sub';

                                    this.selectedParent = blogCategory.parents[0];

                                    let categoryIndex = this.parentCategoriesList.findIndex((listedCategory) => listedCategory.id == blogCategory.parents[0].id)

                                    if(categoryIndex == -1){

                                        this.loadBlogCategories(null);

                                    }

                                }

                                this.$refs.blogCategoryFormObserver.reset();

                                this.tempPreviewCategory = _.cloneDeep(blogCategory);

                            }else{

                                let index = this.blogCategoriesList.findIndex((category) => category.id == -1);
                                
                                if(index != -1){
                    
                                    this.blogCategoriesList.splice(index, 1);
                    
                                }

                                if(!this.blogCategorySaved && (this.tempPreviewCategory.id != '' && this.tempPreviewCategory.id != listedBlogCategory.id)){
                                
                                    let tempIndex = this.blogCategoriesList.findIndex(x=>x.id == this.tempPreviewCategory.id);
                    
                                    this.blogCategoriesList[tempIndex] = this.tempPreviewCategory;
                    
                                }

                                this.$refs.previewFileInput.value = ''

                                this.resetImageValidations();

                                let blogCategory = listedBlogCategory;

                                this.previewForm = blogCategory;
                                this.previewForm.parent_id = blogCategory.parents.length != 0 ? blogCategory.parents[0] : null;
                                this.previewForm.index = i;
                                this.previewForm.image_src = null;
                                this.previewForm.image = blogCategory.image;

                                this.checkedItems = [];

                                if(blogCategory.parent_id == null){

                                    this.selectedCategoryType = 'main';

                                }else{

                                    this.selectedCategoryType = 'sub';

                                    this.selectedParent = blogCategory.parents[0];

                                    let categoryIndex = this.parentCategoriesList.findIndex((listedCategory) => listedCategory.id == blogCategory.parents[0].id)

                                    if(categoryIndex == -1){

                                        this.loadBlogCategories(null);

                                    }

                                }
                                
                                // this.parentCategoriesList = this.blogCategoriesList.filter((x)=>{

                                //     if(x.id != blogCategory.id && x.created_by_user){

                                //         return x;

                                //     }

                                // });

                                this.$refs.blogCategoryFormObserver.reset();

                                this.tempPreviewCategory = _.cloneDeep(blogCategory);

                            }

                        }

                    }

                }   

            }else{

                if(!this.tourIsActive){
                
                    if(listedBlogCategory.id != -1){

                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    type: "warning",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                }
                            ).then(()=>{

                                let index = this.blogCategoriesList.findIndex((category) => category.id == -1);
                                
                                if(index != -1){
                    
                                    this.blogCategoriesList.splice(index, 1);
                    
                                }

                                if(!this.blogCategorySaved){

                                    let tempIndex = this.blogCategoriesList.findIndex(x=>x.id == this.tempPreviewCategory.id);

                                    if(tempIndex != -1){

                                        this.blogCategoriesList[tempIndex] = this.tempPreviewCategory;

                                    }

                                }

                                this.resetImageValidations();

                                let blogCategory = listedBlogCategory;

                                this.previewForm = blogCategory;
                                this.previewForm.parent_id = blogCategory.parents.length != 0 ? blogCategory.parents[0] : null;
                                this.previewForm.index = i;
                                this.previewForm.image_src = null;
                                this.previewForm.image = blogCategory.image;

                                this.checkedItems = [];

                                if(blogCategory.parent_id == null){

                                    this.selectedCategoryType = 'main';

                                }else{

                                    this.selectedCategoryType = 'sub';

                                    this.selectedParent = blogCategory.parents[0];

                                    let categoryIndex = this.parentCategoriesList.findIndex((listedCategory) => listedCategory.id == blogCategory.parents[0].id)

                                    if(categoryIndex == -1){

                                        this.loadBlogCategories(null);

                                    }

                                }
                                
                                // this.parentCategoriesList = this.blogCategoriesList.filter((x)=>{

                                //     if(x.id != blogCategory.id && x.created_by_user){

                                //         return x;

                                //     }

                                // });

                                this.$refs.blogCategoryFormObserver.reset();

                                this.tempPreviewCategory = _.cloneDeep(blogCategory)

                            }).catch(() => {})

                        }else{

                            let index = this.blogCategoriesList.findIndex((category) => category.id == -1);
                                
                            if(index != -1){
                
                                this.blogCategoriesList.splice(index, 1);
                
                            }
                
                            if(!this.blogCategorySaved && (this.tempPreviewCategory.id != '' && this.tempPreviewCategory.id != listedBlogCategory.id)){
                                
                                let tempIndex = this.blogCategoriesList.findIndex(x=>x.id == this.tempPreviewCategory.id);
                                
                                if(tempIndex != -1){

                                    this.blogCategoriesList[tempIndex] = this.tempPreviewCategory;

                                }

                            }


                            if(this.tempPreviewCategory.id != '' && this.tempPreviewCategory.id == listedBlogCategory.id){

                                let tempIndex = this.blogCategoriesList.findIndex(x=>x.id == this.tempPreviewCategory.id);

                                this.blogCategoriesList[tempIndex] = _.cloneDeep(this.storeBlogCategories[tempIndex]);

                                this.$refs.previewFileInput.value = ''

                                this.resetImageValidations();

                                let blogCategory = _.cloneDeep(this.blogCategoriesList[tempIndex])

                                this.previewForm = this.blogCategoriesList[tempIndex];
                                this.previewForm.parent_id = blogCategory.parents.length != 0 ? blogCategory.parents[0] : null;
                                this.previewForm.index = i;
                                this.previewForm.image_src = null;
                                this.previewForm.image = blogCategory.image;

                                this.checkedItems = [];

                                if(blogCategory.parent_id == null){

                                    this.selectedCategoryType = 'main';

                                }else{

                                    this.selectedCategoryType = 'sub';

                                    this.selectedParent = blogCategory.parents[0];

                                    let categoryIndex = this.parentCategoriesList.findIndex((listedCategory) => listedCategory.id == blogCategory.parents[0].id)

                                    if(categoryIndex == -1){

                                        this.loadBlogCategories(null);

                                    }

                                }

                                this.$refs.blogCategoryFormObserver.reset();

                                this.tempPreviewCategory = _.cloneDeep(blogCategory);

                            }else{

                                this.$refs.previewFileInput.value = '';

                                this.resetImageValidations();

                                let blogCategory = listedBlogCategory;

                                this.previewForm = blogCategory;
                                this.previewForm.parent_id = blogCategory.parents.length != 0 ? blogCategory.parents[0] : null;
                                this.previewForm.index = i;
                                this.previewForm.image_src = null;
                                this.previewForm.image = blogCategory.image;

                                this.checkedItems = [];

                                if(blogCategory.parent_id == null){

                                    this.selectedCategoryType = 'main';

                                }else{

                                    this.selectedCategoryType = 'sub';

                                    this.selectedParent = blogCategory.parents[0];

                                    let categoryIndex = this.parentCategoriesList.findIndex((listedCategory) => listedCategory.id == blogCategory.parents[0].id)

                                    if(categoryIndex == -1){

                                        this.loadBlogCategories(null);

                                    }

                                }
                                
                                // this.parentCategoriesList = this.blogCategoriesList.filter((x)=>{

                                //     if(x.id != blogCategory.id && x.created_by_user){

                                //         return x;

                                //     }

                                // });

                                this.$refs.blogCategoryFormObserver.reset();

                                this.tempPreviewCategory = _.cloneDeep(blogCategory);

                            }
                            
                        }

                    }

                }

            }

        },
        previewSearchedBlogCategory(listedBlogCategory,i){

            let index = this.blogCategoriesList.findIndex((category) => category.id == -1);
                                
            if(index != -1){

                this.blogCategoriesList.splice(index, 1);

            }

            if(!this.blogCategorySaved){

                let tempIndex = this.blogCategoriesList.findIndex(x=>x.id == this.tempPreviewCategory.id);

                if(tempIndex != -1){

                    this.blogCategoriesList[tempIndex] = this.tempPreviewCategory;

                }

            }

            this.resetImageValidations();

            let blogCategory = listedBlogCategory;

            this.previewForm = blogCategory;
            this.previewForm.parent_id = blogCategory.parents.length != 0 ? blogCategory.parents[0] : null;
            this.previewForm.index = i;
            this.previewForm.image_src = null;
            this.previewForm.image = blogCategory.image;

            this.checkedItems = [];

            if(blogCategory.parent_id == null){

                this.selectedCategoryType = 'main';

            }else{

                this.selectedCategoryType = 'sub';

                this.selectedParent = blogCategory.parents[0];
                
                let categoryIndex = this.parentCategoriesList.findIndex((listedCategory) => listedCategory.id == blogCategory.parents[0].id)

                if(categoryIndex == -1){

                    this.loadBlogCategories(null);

                }

            }
            
            // this.parentCategoriesList = this.blogCategoriesList.filter((x)=>{

            //     if(x.id != blogCategory.id && x.created_by_user){

            //         return x;

            //     }

            // });

            this.$refs.blogCategoryFormObserver.reset();

            this.tempPreviewCategory = _.cloneDeep(blogCategory);

        },
        closePreview(){

            if(this.previewForm.isTouched){

                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded.", 
                    "Warning", 
                    {
                        type: "warning",
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                    }
                ).then(async () => {

                    let index = this.blogCategoriesList.findIndex((category) => category.id == -1);
                                
                    if(index != -1){
            
                        this.blogCategoriesList.splice(index, 1);
            
                    }
            
                    if(!this.blogCategorySaved){
            
                        let tempIndex = this.blogCategoriesList.findIndex(x=>x.id == this.tempPreviewCategory.id);
                        
                        if(tempIndex != -1){

                            this.blogCategoriesList[tempIndex] = this.tempPreviewCategory;

                        }
            
                    }

                    if(this.previewForm.id != -1){

                        this.$refs.previewFileInput.value = ''

                    }else{

                        this.$refs.addPreviewFileInput.value = ''

                    }

                    this.previewForm.id = '';

                    // this.previewForm = _.cloneDeep(this.defaultPreviewForm);

                    this.selectedParent = {};

                    this.selectedCategoryType = '';

                    this.blogCategorySaved = false;
                    
                    this.tempBlogCategory = _.cloneDeep(this.defaultPreviewForm);

                    this.tempPreviewCategory = _.cloneDeep(this.defaultPreviewForm);

                    this.resetImageValidations();

                }).catch(() => {})

            }else{

                let index = this.blogCategoriesList.findIndex((category) => category.id == -1);
                                
                if(index != -1){
        
                    this.blogCategoriesList.splice(index, 1);
        
                }
        
                if(!this.blogCategorySaved){
        
                    let tempIndex = this.blogCategoriesList.findIndex(x=>x.id == this.tempPreviewCategory.id);
        
                    if(tempIndex != -1){

                        this.blogCategoriesList[tempIndex] = this.tempPreviewCategory;

                    }
        
                }

                if(this.previewForm.id != -1){

                    this.$refs.previewFileInput.value = ''

                }else{

                    this.$refs.addPreviewFileInput.value = ''

                }

                this.previewForm.id = '';

                // this.previewForm = _.cloneDeep(this.defaultPreviewForm);

                this.selectedParent = {};

                this.selectedCategoryType = '';

                this.blogCategorySaved = false;
                
                this.tempBlogCategory = _.cloneDeep(this.defaultPreviewForm);

                this.tempPreviewCategory = _.cloneDeep(this.defaultPreviewForm);

                this.resetImageValidations();

            }

        },
        resetPreview(){

            let index = this.blogCategoriesList.findIndex((blogCategory) => blogCategory.id == -1);
      
            if(index != -1){

                this.blogCategoriesList.splice(index, 1);

            }

            this.previewForm.id = '';

            // this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.selectedParent = {};

            this.selectedCategoryType = '';

            this.tempBlogCategory = _.cloneDeep(this.defaultPreviewForm);

            this.tempPreviewCategory = _.cloneDeep(this.defaultPreviewForm);

            this.resetImageValidations();

        },
        createBlogCategory(){

            if(this.blogCategoriesLoading){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "Blog categories are being retrieved.",
                });

                return

            }
            
            if(this.blogCategoryIsSaving){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "A blog category is currently being processed.",
                });

                return

            }

            let index = this.blogCategoriesList.findIndex(x=>x.id == -1);

            if(index != -1){

                // this.$notify({
                //   type: "error",
                //   title: "Can't Add New Category",
                //   message: "you have an unsaved blog category in the list",
                // });

                return
                
            }

            this.closePreview();

            this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.previewForm.id = -1;

            let parent = {
                name: 'Main'
            }

            this.previewForm.parents.push(parent);

            let tempBlogCategory = this.previewForm;

            this.selectedCategoryType = 'main';

            this.blogCategoriesList.unshift(tempBlogCategory);

            // this.parentCategoriesList = this.blogCategoriesList.filter((x)=>{

            //     if(x.id != this.previewForm.id && x.created_by_user){

            //         return x;

            //     }

            // });

            this.loadBlogCategories(null);

            this.checkedItems = [];

            this.$refs.blogCategoryFormObserver.reset();

        },
        //preview form open/close & reset end

        //add/update & delete blog category start
        validate(){

            this.$refs.blogCategoryFormObserver.validate().then(success => {

                if(!success){

                    const errors = Object.entries(this.$refs.blogCategoryFormObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                    this.$refs.blogCategoryFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });
                    
                    if(errors.length > 0){

                        if(this.previewForm.id == -1 && !this.previewForm.addImage){

                            this.imageValidations.imgIsRequired = true;

                        }else if(this.previewForm.id != -1 && !this.previewForm.image){

                            this.imageValidations.imgIsRequired = true;

                        }

                    }else{

                        if(this.previewForm.id == -1 && !this.previewForm.addImage){

                            this.imageValidations.imgIsRequired = true;

                            document.querySelector("#category-featured-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                        }else if(this.previewForm.id != -1 && !this.previewForm.image){

                            this.imageValidations.imgIsRequired = true;

                            document.querySelector("#category-featured-image-update").scrollIntoView({ behavior: 'smooth',block: "center" });

                        }

                    }

                }else{

                    if(this.previewForm.id == -1 && !this.previewForm.addImage){

                        this.imageValidations.imgIsRequired = true;

                        document.querySelector("#category-featured-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                    }else if(this.previewForm.id != -1 && !this.previewForm.image){

                        this.imageValidations.imgIsRequired = true;

                        document.querySelector("#category-featured-image-update").scrollIntoView({ behavior: 'smooth',block: "center" });

                    }else if(this.previewForm.id == -1){

                        this.resetImageValidations();

                        this.addBlogCategoryHandle();

                    }else{

                        this.resetImageValidations();

                        this.updateBlogCategoryHandle();

                    }

                }

            });

        },
        async addBlogCategoryHandle(){

            if(!this.previewForm.addImage){

                this.$notify({
                    type: 'error',
                    title: 'Alert',
                    message: "Category Image is required.",
                });

                this.imageValidations.imgIsRequired = true;

                document.querySelector("#category-featured-image")
                .scrollIntoView({ behavior: 'smooth',block: "center" });

                return

            }

            let formData = new FormData();

            formData.append('name',this.previewForm.name);
            formData.append('slug',this.previewForm.slug);
            formData.append('excerpt',this.previewForm.excerpt);
            formData.append('description',this.previewForm.description);
            formData.append('type',this.selectedCategoryType);

            if(this.selectedCategoryType == 'sub'){

                formData.append('parent_id',this.previewForm.parent_id.id);

            }

            formData.append('isActive',this.previewForm.isActive ? 1 : 0);
            formData.append('isIndexed',this.previewForm.isIndexed ? 1 : 0);

            if(this.previewForm.meta_title){

                formData.append('meta_title', this.previewForm.meta_title);

            }

            if(this.previewForm.meta_description){

                formData.append('meta_description', this.previewForm.meta_description);

            }

            if(this.previewForm.addImage){

                formData.append('image', this.previewForm.addImage);

            }

            this.blogCategoryIsSaving = true
            try{
                let res = await this.$axios.post('/blog-category/add', formData);
                if(res.data.status_code == '1043'){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.$store.commit('blogCategories_module/add_blogCategories_list',res.data.category);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    this.resetPreview();

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.name){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.name[0],
                        });

                    }else if(error.response.data.error.slug){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.slug[0],
                        });

                    }else if(error.response.data.error.includes('hierarchy') || 
                    error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });
                    
                }

            }finally{

                this.blogCategoryIsSaving = false;

            }

        },
        async updateBlogCategoryHandle(){

            let formData = new FormData();

            formData.append('id',this.previewForm.id);
            formData.append('name',this.previewForm.name);
            formData.append('slug',this.previewForm.slug);
            formData.append('excerpt',this.previewForm.excerpt);
            formData.append('description',this.previewForm.description);
            formData.append('type',this.selectedCategoryType);

            if(this.selectedCategoryType == 'sub'){

                formData.append('parent_id',this.previewForm.parent_id.id);

            }

            formData.append('isActive',this.previewForm.isActive ? 1 : 0);
            formData.append('isIndexed',this.previewForm.isIndexed ? 1 : 0);

            if(this.previewForm.meta_title){

                formData.append('meta_title', this.previewForm.meta_title);

            }

            if(this.previewForm.meta_description){

                formData.append('meta_description', this.previewForm.meta_description);

            }

            if(this.previewForm.image_src){

                formData.append('image', this.previewForm.image);

            }

            this.blogCategoryIsSaving = true
            try{
                let res = await this.$axios.post('/blog-category/update',formData);
                if(res.data.status_code == '10432'){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    let tempIndex = this.blogCategoriesList.findIndex((listedCategory) => listedCategory.id == res.data.category.id);

                    this.blogCategoriesList[tempIndex] = res.data.category;

                    this.$store.commit('blogCategories_module/update_blogCategories_list',res.data.category);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.$store.commit('blogs_module/CLEAR_MODULE');

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    if(this.previewForm.id == res.data.category.id){

                        this.blogCategorySaved = true;

                        this.closePreview();

                    }

                }   
            }catch(error){

                if(error.response){

                    if(error.response.data.error.name){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.name[0],
                        });

                    }else if(error.response.data.error.slug){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.slug[0],
                        });

                    }else if(error.response.data.error.id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.id[0],
                        });

                    }else if(error.response.data.error.includes('hierarchy') || 
                    error.response.data.error.includes('hierarchical structure') || 
                    error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }
                
                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.blogCategoryIsSaving = false;

            }

        },
        deleteBlogCategoryHandle(listedBlogCategory,index){

            if(!this.tourIsActive){
            
                MessageBox.confirm(
                    'Are you sure you want to delete the blog category?',
                    'Warning',
                    {
                        type: 'warning',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel',
                    }
                ).then(async ()=>{

                    if(!listedBlogCategory.created_by_user){

                        this.$notify({
                            type: 'warning',
                            title: 'Changes Discarded',
                            message: "Default blog category cannot be modified.",
                        });

                        return 

                    }

                    this.deletingItems.push(listedBlogCategory.id);
                    this.deleteLoader = true;

                    let loader = Loading.service({
                        text: "The blog category is being removed. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.delete(`/blog-category/remove/${listedBlogCategory.id}`);
                        if(res.data.status_code == '1037'){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: "Blog category has been successfully removed.",
                            });

                            // this.$store.commit('blogCategories_module/delete_blogCategories_list',index);

                            this.$store.commit('gallery_module/CLEAR_MODULE');

                            this.$store.commit('blogs_module/CLEAR_MODULE');

                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                            //removing deleted item from checked items list
                            this.checkedItems = this.checkedItems.filter((item) => {

                                if(item != listedBlogCategory.id){
                                    
                                    return item;
                                    
                                }

                            });

                            this.blogCategoriesLoading = true

                            this.$store.dispatch('blogCategories_module/fetchStoreBlogCategoriesRequest',{size: 15, page: 1, loadData: 'new'});

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.error.blog_category_id){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.error.blog_category_id[0],
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                        this.deletingItems = [];
                        this.deleteLoader = false;

                    }

                }).catch(() => {})

            }

        },
        async updateBlogCategoryStatusHandle(listedBlogCategory,index){

            if(!this.tourIsActive){
            
                let formData = new FormData();
      
                formData.append('blog_category_id', listedBlogCategory.id);
                formData.append('isActive', listedBlogCategory.isActive == 1 ? 0 : 1);
                
                let loader = Loading.service({
                    text: "The blog category is undergoing status update. Please wait!",
                    fullscreen: true,
                });

                try{
                    let res = await this.$axios.post('/blog-category/status/toggle',formData);
                    if(res.data.status_code == "10431"){

                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        let payload = {
                            index: index,
                            updatedBlogCategory: res.data.blog_category,
                        }

                        this.$store.commit('blogs_module/CLEAR_MODULE');

                        this.$store.commit('blogCategories_module/update_blogCategories_status',payload);

                    }
                }catch(error){

                    this.blogCategoriesList[index].isActive = !this.blogCategoriesList[index].isActive

                    if(error.response){

                        if(error.response.data.error.blog_category_id){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error.blog_category_id[0],
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    loader.close();

                }

            }

        },
        //add/update & delete blog category end

        //bulk action start
        blogCategoriesBulkActionHandle(action){

            this.resetPreview();

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async ()=>{

                if(action == 'active' || action == 'disable'){

                    let formData = new FormData();

                    formData.append('resource_type', 'blog_category');
                    formData.append("resource_ids", JSON.stringify(this.checkedItems));
                    formData.append("action", action);

                    let loader = Loading.service({
                        text: "The selected blog categories are undergoing status update. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.post("/bulk-action", formData);
                        if(res.data.status_code == "2031"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: 'Blog categories status has been successfully changed.',
                            });

                            let payload = {
                                action: 'statusChange',
                                status: action == "active" ? 1 : 0,
                                listIds: this.checkedItems
                            }

                            this.$store.commit('blogCategories_module/categories_bulk_action',payload);

                            this.checkedItems = [];

                        }
                    }catch(error){

                        this.deleteLoader = false;

                        if(error.response){

                            if(error.response.data.message){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: "One or more of the selected blog categories may have been deleted or didn't available.",
                                });

                            }

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                    }

                }else if(action == 'delete'){

                    let formData = new FormData();

                    formData.append('resource_type', 'blog_category');
                    formData.append("resource_ids", JSON.stringify(this.checkedItems));
                    formData.append("action", action);

                    this.deletingItems = _.cloneDeep(this.checkedItems)
                    this.deleteLoader = true;

                    let loader = Loading.service({
                        text: "The selected blog categories are being removed. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.post("/bulk-action", formData);
                        if(res.data.status_code == "2031"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: 'Blog categories have been successfully removed.',
                            });

                            // let payload = {
                            //     action: 'delete',
                            //     listIds: this.checkedItems
                            // }

                            // this.$store.commit('blogCategories_module/categories_bulk_action',payload);

                            this.blogCategoriesLoading = true

                            this.$store.dispatch('blogCategories_module/fetchStoreBlogCategoriesRequest',{size: 15, page: 1, loadData: 'new'});

                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                            this.checkedItems = [];
                            this.deletingItems = [];
                            this.deleteLoader = false;

                        }
                    }catch(error){

                        this.deletingItems = [];
                        this.deleteLoader = false;

                        if(error.response){

                            if(error.response.data.message){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: "One or more of the selected blog categories may have been deleted or didn't available.",
                                });

                            }

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                    }

                }

            }).catch(() => {})

        },
        //bulk action end

        //search blog category start
        searchBlogCategory(keyword){

            this.$refs.module_search.searchedKeyword = keyword

            this.$refs.module_search.search();

        },
        //search blog category end

        //other methods start
        async changeImage(e){
      
            this.imageValidates = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }
            
            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.name.length > 50){

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file name must not exceed 50 characters.",
                    }); 

                    this.imageValidations.imgIsRequired = false;

                    if(this.$refs.addPreviewFileInput){

                        this.$refs.addPreviewFileInput.value = '';

                    }

                    return

                }

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                
                    this.previewForm.addImage = file;
                    this.previewForm.addImage_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false;

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6);
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    if(!this.previewForm.addImage){

                        this.imageValidations.imgIsRequired = true;

                    }
                
                    // this.imageValidations.imgInvalidFormat = true

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    if(!this.previewForm.addImage){

                        this.imageValidations.imgIsRequired = true;

                    }
                    // this.imageValidations.isNotImg = true

                }

            }

        },
        async changeImagePreviewForm(e){

            this.imageValidates = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.name.length > 50){

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file name must not exceed 50 characters.",
                    }); 

                    this.imageValidations.imgIsRequired = false;

                    if(this.$refs.previewFileInput){

                        this.$refs.previewFileInput.value = '';

                    }

                    return

                }

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                
                    this.previewForm.image = file;
                    this.previewForm.image_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false;

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6);
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    if(!this.previewForm.image){

                        this.imageValidations.imgIsRequired = true;

                    }
                    
                    // this.imageValidations.imgInvalidFormat = true

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    if(!this.previewForm.image){

                        this.imageValidations.imgIsRequired = true;

                    }

                    // this.imageValidations.isNotImg = true

                }

            }

        },
        resetImageValidations(){

            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

        },
        livePreview(listedBlogCategory){

            if(listedBlogCategory.isActive && (listedBlogCategory.blogs.length > 0)){

                window.open('https://' + this.settings.domain + '/blog/' + listedBlogCategory.slug);

            }else{

                this.$notify({
                    type: 'info',
                    title: 'Preview Unavailable',
                    message: "To preview, ensure category is active and assigned to at least one blog.",
                });

            }

        },
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        generateUrl(listedBlogCategory){
            
            if(listedBlogCategory.image){

                if(typeof(listedBlogCategory.image) == "string"){

                    return this.base_image_url + this.settings.store_id + '/blog_category/' + listedBlogCategory.image;

                }else{

                    return listedBlogCategory.image_src;

                }

            }

        },
        checkedItemHandle(blogCategoryId){

            if(!this.tourIsActive && blogCategoryId != -1 && !this.previewForm.id){

                let index = this.checkedItems.findIndex(item => item == blogCategoryId);

                if(index != -1){

                    this.checkedItems.splice(index,1);

                }else{

                    this.checkedItems.push(blogCategoryId);

                }

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive && !this.previewForm.id){

                if((this.checkedItems.length == this.blogCategoriesList.length) ||
                (this.checkedItems.length == this.blogCategoriesList.length)){

                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.blogCategoriesList.length){

                    this.checkedItems = [];
                    
                    this.blogCategoriesList.forEach(category => {

                        if(category.id != -1){

                            this.checkedItems.push(category.id);

                        }

                    });

                }

            }

        },
        clearSelection(){

            MessageBox.confirm(
                'Do you really want to uncheck the selected records?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async () => {

                this.checkedItems = [];

            }).catch(() => {})

        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

        },
        blogCategoriesKeyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

                    this.closePreview();

                }

            }

        },
        //other methods end

        //load blog categories method starts here
        customProductCategoryName({ name }){

            if(name){

                if(name.length > 30){

                    let shortenedName = name.substr(0, 30);

                    return `${shortenedName}...`;

                }else{

                    return `${name}`;

                }

            }else{

                return ``;

            }

        },
        async loadBlogCategories(query){

            if(query){

                clearTimeout(this.parentCategoriesTimer);

                this.parentCategoriesTimer = setTimeout(async () => {

                    this.parentCategoriesLoading = true;
                    try{
                        let res = await this.$axios.get("getAllBlogCategories",{
                            params:{
                                offset: 5,
                                search: query ? query : null
                            }
                        });
                        if(res.data.status_code == "1042"){

                            if(this.previewForm.id != '' && this.previewForm.id != -1){

                                this.parentCategoriesList = res.data.categories.data.filter((x) => {

                                    if(x.id != this.previewForm.id && x.created_by_user){

                                        return x;

                                    }

                                });

                                if(this.previewForm.parents.length != 0){

                                    let categoryIndex = this.parentCategoriesList.findIndex((category) => category.id == this.previewForm.parents[0].id)

                                    if(categoryIndex == -1){

                                        this.parentCategoriesList.unshift(this.previewForm.parents[0]);

                                    }

                                }

                            }else{

                                this.parentCategoriesList = res.data.categories.data.filter((x) => {

                                    if(x.id != this.previewForm.id && x.created_by_user){

                                        return x;

                                    }

                                });

                            }

                        }
                    }catch(error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{
                        
                        this.parentCategoriesLoading = false;

                    }

                }, 500);

            }else{

                this.parentCategoriesLoading = true;
                try{
                    let res = await this.$axios.get("getAllBlogCategories",{
                        params:{
                            offset: 5,
                            search: query ? query : null
                        }
                    });
                    if(res.data.status_code == "1042"){

                        if(this.previewForm.id != '' && this.previewForm.id != -1){

                            this.parentCategoriesList = res.data.categories.data.filter((x) => {

                                if(x.id != this.previewForm.id && x.created_by_user){

                                    return x;

                                }

                            });

                            if(this.previewForm.parents.length != 0){

                                let categoryIndex = this.parentCategoriesList.findIndex((category) => category.id == this.previewForm.parents[0].id)

                                if(categoryIndex == -1){

                                    this.parentCategoriesList.unshift(this.previewForm.parents[0]);

                                }

                            }

                        }else{

                            this.parentCategoriesList = res.data.categories.data.filter((x) => {

                                if(x.id != this.previewForm.id && x.created_by_user){

                                    return x;

                                }

                            });

                        }

                    }
                }catch(error){

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response ? error.response.data.message : error.message,
                    });

                }finally{
                    
                    this.parentCategoriesLoading = false;

                }

            }

        },
        //load blog categories method ends here

        //tour methods start
        startBlogCategoriesTour(){

            this.$tours['blogCategoriesTour'].start();

            this.tourIsActive = true;

            if(this.previewForm.id != ''){

                this.resetPreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 7){

                document.querySelector(".tableActionBtns").style.position = "static";

                document.querySelector(".productSlideBox").style.overflowY  = "hidden";

                this.createBlogCategory();

            }

            if(currentStep + 1 == 12){

                this.selectedCategoryType = 'sub';

            }

            if(currentStep >= 19){

                document.querySelector(".tableActionBtns").style.position = "sticky";

            }

            if(currentStep + 1 == 22){

                if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep + 1 == 23){

                this.expand();

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 7){

                this.resetPreview();

                document.querySelector(".tableActionBtns").style.position = "sticky";

                document.querySelector(".productSlideBox").style.overflowY  = "auto";

            }

            if(currentStep == 12){

                this.selectedCategoryType = 'main';

            }

            if(currentStep <= 20){

                document.querySelector(".tableActionBtns").style.position = "static";

            }

            if(currentStep == 22){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep == 23){

                if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

        },
        handleTourStop(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourFinish(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourSkip(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.resetPreview();

            this.tourIsActive = false;

        },
        //tour methods end

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },

    },
    mounted(){

        this.$refs.blogCategoriesTable.addEventListener('scroll', this.handleScrollEvent);

        window.addEventListener('keydown',this.blogCategoriesKeyEvent);

        window.addEventListener('beforeunload', (event) => {

            let index = null;

            this.blogCategoriesList.forEach((blogCategory) => {

                if(blogCategory.id == -1){

                    index = this.blogCategoriesList.indexOf(blogCategory);

                }

            });

            if(index != null){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";

            }

        });

        //add form top navigation scroll handle
        this.$refs.viewInputForm.addEventListener("scroll",(e)=>{

            let maxScrollTop = e.target.scrollHeight - e.target.clientHeight;

            if(e.target.scrollTop >= maxScrollTop){

                document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {

                    item.classList.remove("active");

                });

                if(!this.tourIsActive){

                    document.querySelector('#category-seo-navbtn').classList.add('active');

                }

            }else{

                document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {

                    item.classList.remove("active");

                });

                if(!this.tourIsActive){

                    document.querySelector('#category-detail-navbtn').classList.add('active');

                }
            
            }

        });

        //master search
        if(this.$route.query.search){
            
            this.$refs.module_search.searchedKeyword = this.$route.query.search

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true

            }

            this.$router.replace({'query': null});

            this.$refs.module_search.search();

        }

    },
    beforeMount(){

        this.blogCategoriesLoading = true
        try{

            if((!this.storeBlogCategories || this.storeBlogCategories.length == 0) && this.blogCategorySearchKeyword == 0){

                this.$store.dispatch('blogCategories_module/fetchStoreBlogCategoriesRequest',{size: 15, page: 1, loadData: 'new'});

            }else{

                this.blogCategoriesList = _.cloneDeep(this.storeBlogCategories);
                this.blogCategoriesLoading = false;

            }

            this.loadBlogCategories(null);

        }catch(error){

            this.blogCategoriesLoading = false;

            this.$message({
                type: 'error',
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

        window.removeEventListener('keydown',this.blogCategoriesKeyEvent);

    },
    beforeRouteLeave(to, from, next){

        let index = null;

        this.blogCategoriesList.forEach((blogCategory) => {

            if(blogCategory.id == -1){

                index = this.blogCategoriesList.indexOf(blogCategory);

            }

        });

        if(window.innerWidth <= 1024){

            document.body.classList.remove('activeIpad');
            document.body.classList.add('hideSideBar');

        }

        if((this.previewForm.id || index != null) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You may have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{

                this.resetPreview();

                next();

            }).catch(()=>{})

        }else{

            next();

        }

    },
}
window.myFunction = function (event) {
    // reset all menu items
    document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {
        item.classList.remove("active");
    });
    // mark as active selected menu item
    event.target.classList.add("active");
};
</script>

<style>
    .category-tab-img {
        height: 400px;
        width: 400px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
    }
    .category-tab-img:hover {
        border-color: #409EFF;
    }
    .category-tab-img img{
    height: 100%;
    width: 100%;
    }
    .category-tab-img svg{
    position: absolute;
    width: 50px;
    top: 43%;
    left: 0;
    right: 0;
    margin: 0 auto;
    fill: #d9d9d9;
    }
    .category-tab-img:hover svg{
    fill: #409EFF;
    }
</style>